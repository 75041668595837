import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import Blogs from "../components/post/blogs"

import "../styles/blog.css"

const About = ({data}) => {
    const {
        allMdx: {nodes: posts},
    } = data

    // console.log(data)

    return (
        <Layout>
            <div className="py-12 blogHero">
                <div className="container mx-auto md:px-24 pt-16">
                    <h1 className="text-3xl md:text-4xl lg:text-6xl text-white text-center font-extralight text-shadow">
                        Business Digest
                    </h1>
                </div>
            </div>
            <div className="container mx-auto px-8 md:px-24 py-8 md:mt-10 mb-16">
                <div className="flex justify-center">
                    <div>
                        <Blogs posts={posts} title="Recently Published Blog Posts"/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        frontmatter {
          title
          author
          category
          readTime
          slug
          date(formatString: "MMMM, Do YYYY")
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        excerpt
      }
    }
  }
`

export default About
