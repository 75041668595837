import React from "react"
import {GatsbyImage, getImage} from "gatsby-plugin-image"

import {Link} from "gatsby"
// import { FaAngleRight } from "react-icons/fa"

const Blog = ({post}) => {
    // console.log(post.frontmatter.title)
    // const { title, image, date, category, readTime, author, excerpt, slug } =
    const {title, image, date, slug} = post.frontmatter
    return (
        <div>
            <li className="flex items-baseline mt-8 mx-6">
                <div>
                    <div className="grid">
                        <div>
                            <GatsbyImage
                                image={getImage(image)}
                                alt={title}
                                className="rounded shadow-md border"
                            />
                        </div>
                        <div className="px-3 my-5">
                            <h4>
                                <Link
                                    to={`/posts/${slug}`}
                                    className="font-light text-lg md:text-xl text-blue-500 hover:underline"
                                >
                                    {title}
                                    {/* {title.slice(0, 80)}... */}
                                </Link>
                            </h4>
                            <p className="text-xs text-gray-600 mt-2">
                                {post.excerpt.slice(0, 100) + "..."}
                            </p>
                            <p className="uppercase text-xs text-gray-600 mt-2 md:textsm">
                                {date}
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        </div>
    )
}

export default Blog
