import React from "react"
import Blog from "./blog"

const Blogs = ({posts, title}) => {
    // console.log(posts)
    return (
        <section className="">
            <h2 className="text-3xl font-light border-b-2 pb-2 border-blue-500">
                {title}
            </h2>
            <article className="grid grid-cols-1 md:grid-cols-3">
                {posts.map(post => {
                    return <Blog post={post} key={post.id}/>
                })}
            </article>
        </section>
    )
}

export default Blogs
